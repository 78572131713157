import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_nyaskosnoren/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "skoinlägg"
    }}>{`Skoinlägg`}</h1>
    <p>{`Våra fötter är det som tar oss fram, dag ut och dag in. Oavsett om det är i löpspåret, på fotbollsplanen, i skogen eller på bussen så lägger vi hela vår vikt, ofta lite till, på våra fötter. Ofta ledder detta i längden till trötta fötter och i värsta fall skador. Inte bara på fötterna utan även i knän och rygg. Ändå envisas många med att helt ignorera hur deras fötter sitter i skorna.`}</p>
    <p>{`Och det är här skoinlägg kommer in. Man kan enkelt säga att skoinlägg kan delas in i två huvudgrupper. Skoinlägg som ska stötta delar av kroppen för att du ska få rätt skoisättning, och skoinlägg som är där för att ge ett mjukt underlag. I många skor, framför allt skor mer inriktade för träning, så finns detta till viss del inbyggt i form av en mjuk sula, dämpade skor och stöd för pronation om man köper den typen av produkt. Däremot är dessa ofta mer "generella" lösningar och för att få något som passar just dig och din fot så är ofta skoinlägg att rekommendera.`}</p>
    <h2 {...{
      "id": "sök-professionell-hjälpa"
    }}>{`Sök professionell hjälpa`}</h2>
    <p>{`Informationen på denna sida ska ses som ett hjälpmedel om du redan vet vad för typ av problem du har med dina fötter och vad du bör göra för att åtgärda detta. Första steget när du får problem med dina fötter är alltid att söka professionell hjälp. Förslagsvis kan du börja med att gå in på `}<a href="https://www.1177.se/Orebrolan/sjukdomar--besvar/skelett-leder-och-muskler/ben-och-fotter/rad-vid-problem-med-dina-fotter/" target="_blank">{`1177 - Råd vid problem med dina fötter`}</a>{` och sedan vidare. När du sedan vet vilka typ av sulor du behöver kan du använda denna sida som en guide.`}</p>
    <h2 {...{
      "id": "olika-typer-av-skoinlägg"
    }}>{`Olika typer av skoinlägg`}</h2>
    <p>{`Förfotsinlägg, gelesulor, halvsulor, helsulor, hålfotsinlägg, partysulor osv. Ja, listan kan göras väldigt lång och det är viktigt att du tänker på syftet, och dina fötters utformning, när du väljer skoinlägg. Ska de användas för löpning? Ska de användas i ett par obekväma finskor för att inte dina fötter ska bli för trötta? Ska de användas i ett par sneakers för att justera din fotisättning och därmed förhindra skador på knä och rygg?`}</p>
    <h3 {...{
      "id": "gelesulor"
    }}>{`Gelesulor`}</h3>
    <p>{`Gelesulor, eller gelsulor som det också kallas, är något utav en "allaround"-lösning för diverse problem med fötter. Gelesulorna består av ett stötdämpande gele-material som ska ge dämpning för dina fötter. Många gelesulor är dessutom anatomiskt uppbyggda med stödpartier av hårade material för att bygga upp och ge stöd åt dina fötter där det behövs som mest. En gelesula är för dig som ofta blir trött i fötter, ben eller rygg och vill ge dina fötter lite mjukare underlag. Extra bra med gelesulor är om du går långt och länga, framför allt på hårade underlag så som stengolv eller asfalt. Ett par gelesulor i bra skick är något som vi rekommenderar alla att ha hemma. Ofta går de att klippa till rätt passform och de är perfekta att slänga i ett par skor som saknar dämpning eller om du vet med dig om att en lång dag i skor på hårt underlag väntar.`}</p>
    <p>{`Som en extra bonus är gelesulor i regel antibakteriella och minskar därmed illaluktande skor.`}</p>
    <h3 {...{
      "id": "förfotsinlägg"
    }}>{`Förfotsinlägg`}</h3>
    <p>{`Förfotsinlägg är precis som namnet antyder ett inlägg som ska hjälpa den främre delen av foten. Syftet med ett förfotsinlägg är att reducera trycket under förfoten och förbättra fotisättningen både vid gång och vid löpning.`}</p>
    <p>{`Ett vanligt tecken på att man behöver ett förfotsinlägg är att man kan få som små stickningar och smärtor i förfoten. En vanlig orsak till detta är att man har ett nedsjunket förfotsvalv eller lider utav plattfot. Ett inlägg för förfoten ger då stöd under foten och bygger upp för att fördela trycket och därmed minska symptomen. Vid plattfot kan det dock ibland vara bättre att använda ett så kallat hålfotsinlägg.`}</p>
    <p>{`Förfotsinlägg består ofta av en så kallad halvsula, som enbart täcker främre delen av foten. Det finns dock fullstora sulor som täcker hela foten men som endast har uppbyggnad och stöd i den främre delen.`}</p>
    <h3 {...{
      "id": "hålfotsinlägg"
    }}>{`Hålfotsinlägg`}</h3>
    <p>{`Det främsta syftet med hålfotsinlägg är att stötta upp hålfoten. Hålfoten är mittendelen av foten på insidan, där man ofta har en upphöjning. Ålder, eller arv, kan medföra att man får så kallad plattfot, eller tendenser till plattfot. Detta innebär att "valvet" i hålfoten sjunker ner lite och foten orkar inte ge det stöd som krävs. Att fotvalvet sjunker ner kan leda till ökad belastning på andra delar av foten. Detta kan i sin tur leda till både fler förslitningsskador i foten men också smärta i rygg och leder.`}</p>
    <p>{`För att motverka detta så använder man sig utav hålfotsinlägg. Dessa har en hård upphöjning i vid hålfoten för att ge stöd åt foten och tvinga den i rätt position och därmed förbättra hållningen för resten av kroppen.`}</p>
    <p>{`Ett hålfotsinlägg har fyra huvudsyften:`}</p>
    <ul>
      <li parentName="ul">{`Distribuera trycket över hela foten.`}</li>
      <li parentName="ul">{`Reducera och förhindra smärta i foten.`}</li>
      <li parentName="ul">{`Ge ökad stabilitet och bättre balans`}</li>
      <li parentName="ul">{`Räta upp kroppen och förbättra hållningen`}</li>
    </ul>
    <p>{`Plattfot, som är en av huvudorsakerna till behov av hålfotsinlägg kan som sagt antingen komma medfött eller med åldern. När det kommer med åldern är det ingenting som kommer plötsligt, utan något som gradvis växer fram. Inte heller brukar det orsakas utav någon plötslig händelse eller skada. I stället är det något som beror på en obalans mellan uppbyggnad och nedbrytning av senan, där nerbrytningen är övervägande vilket leder till att senan gradvis försvagas, se `}<a href="https://capio.se/sjukdomar-och-besvar/leder-muskler-och-skelett/hander-och-fotter/plattfot/" target="_blank">{`Capio - Plattfot`}</a>{`.`}</p>
    <p>{`Som med de flesta sulor så har alla hålfotsinlägg olika former, olika uppbyggnader och olika storlekar. Och precis som när du väljer skor så bör du även beställa hem ett par olika hålfotsinlägg för att kunna prova ut de som just passar dig och dina fötter.`}</p>
    <h2 {...{
      "id": "när-bör-man-använda-skoinlägg"
    }}>{`När bör man använda skoinlägg?`}</h2>
    <p>{`Korta svaret är egentligen nästan alltid. Men för att förenkla det lite gran punktar vi ner fem olika syften med skoinlägg och när man bör använda dem.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`För att behandla platta fötter`}</strong>{` - Platta fötter är en vanlig orsak till smärta i fötterna. Genom att använda rätt skoinlägg kan du bygga upp under foten och förhindra att du sätter i foten platt. Detta kan minska smärta.`}</li>
      <li parentName="ul"><strong parentName="li">{`För att förbättra hållningen`}</strong>{` - Hur fötterna sitter i skon och hur du sätter ner fötterna i marken påverkar inte bara dina fötter, utan hela kroppen och din hållning. Om du har platta fötter så försöker kroppen kompensera för detta vilket kan leda till dålig hållning och därmed också smärta i knä och rygg. Genom rätt skoinlägg kan du förbättra förutsättningarna för en bra hållning.`}</li>
      <li parentName="ul"><strong parentName="li">{`För att bli av med smärta i fötterna`}</strong>{` - Rätt skoinlägg kan hjälpa till att sprida ut trycket på dina fötter. Detta gör att du kan bli av med smärta i t.ex. hälar vilket är väldigt vanligt.`}</li>
      <li parentName="ul"><strong parentName="li">{`För att förhindra idrottsskador`}</strong>{` - De snabba och kraftiga rörelserna vid idrott har stor påfrestning på dina fötter. Det är därför extra viktigt med rätt passform och rätt fotisättning, något som ett par bra skoinlägg kan hjälpa till med.`}</li>
      <li parentName="ul"><strong parentName="li">{`För mer hälsosamma fötter`}</strong>{` - Även om du inte har några problem med dina fötter idag så kan långsiktigt felaktig fotisättning leda till skador när du blir äldre. Med rätt inlägg så kan du reducera och minimera risken att drabbas av problem längre fram.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      